export const categories = [
  'all',
  'office',
  'living room',
  'kitchen',
  'bedroom',
  'dining',
  'kids',
];

export const sortBy = [
  'Price: Low to High',
  'Price: High to Low',
  'Alphabetically: A to Z',
  'Alphabetically: Z to A',
];
