/* eslint-disable no-dupe-keys */
export const shippingFees = {
  ADAMS: 70000,
  BACARRA: 89500,
  BADOC: 41200,
  BANGUI: 56451,
  "BANNA (ESPIRITU)": 55500,
  "BATAC CITY": 61642,
  BURGOS: 84121,
  CARASI: 75641,
  CURRIMAO: 76441,
  DINGRAS: 61642,
  DUMALNEG: 616453,
  "LAOAG CITY": 42151,
  MARCOS: 65331,
  "NUEVA ERA": 16422,
  PAGUDPUD: 64221,
  PAOAY: 64331,
  PASUQUIN: 86551,
  PIDDIG: 71745,
  PINILI: 64261,
  "SAN NICOLAS": 87565,
  SARRAT: 65471,
  SOLSONA: 97651,
  VINTAR: 76481,
  ALILEM: 54251,
  BANAYOYO: 64531,
  BANTAY: 61642,
  BURGOS: 64361,
  CABUGAO: 53261,
  "CANDON CITY": 75445,
  CAOAYAN: 74231,
  CERVANTES: 67571,
  GALIMUYOD: 61653,
  "GREGORIO DEL PILAR (CONCEPCION)": 64531,
  LIDLIDDA: 87531,
  MAGSINGAL: 87551,
  NAGBUKEL: 65471,
  NARVACAN: 76541,
  "QUIRINO (ANGKAKI)": 65431,
  "SALCEDO (BAUGEN)": 87661,
  "SAN EMILIO": 87651,
  "SAN ESTEBAN": 87431,
  "SAN ILDEFONSO": 76441,
  "SAN JUAN (LAPOG)": 54331,
  "SAN VICENTE": 65431,
  SANTA: 87651,
  "SANTA CATALINA": 87641,
  "SANTA CRUZ": 76551,
  "SANTA LUCIA": 87651,
  "SANTA MARIA": 76551,
  SANTIAGO: 65471,
  "SANTO DOMINGO": 87561,
  SIGAY: 65441,
  SINAIT: 76541,
  SUGPON: 65441,
  SUYO: 65441,
  TAGUDIN: 65451,
  "VIGAN CITY": 51764,
  AGOO: 65451,
  ARINGAY: 87651,
  BACNOTAN: 65451,
  BAGULIN: 65441,
  BALAOAN: 76541,
  BANGAR: 65481,
  BAUANG: 76451,
  BURGOS: 87651,
  CABA: 87661,
  "CITY OF SAN FERNANDO": 64531,
  LUNA: 87551,
  NAGUILIAN: 87551,
  PUGO: 98651,
  ROSARIO: 87651,
  "SAN GABRIEL": 76551,
  "SAN JUAN": 76551,
  "SANTO TOMAS": 51764,
  SANTOL: 98767,
  SUDIPEN: 87651,
  TUBAO: 76587,
  AGNO: 63431,
  AGUILAR: 52421,
  "ALAMINOS CITY": 57451,
  ALCALA: 46231,
  ANDA: 75441,
  ASINGAN: 64331,
  BALUNGAO: 65331,
  BANI: 57641,
  BASISTA: 65431,
  BAUTISTA: 64221,
  BAYAMBANG: 65331,
  BINALONAN: 65341,
  BINMALEY: 45231,
  BOLINAO: 64241,
  BUGALLON: 65431,
  BURGOS: 64331,
  CALASIAO: 64331,
  "DAGUPAN CITY": 76531,
  DASOL: 65331,
  INFANTA: 54231,
  LABRADOR: 64331,
  LAOAC: 54361,
  LINGAYEN: 65441,
  MABINI: 61764,
  MALASIQUI: 75641,
  MANAOAG: 65441,
  MANGALDAN: 45631,
  MANGATAREM: 67541,
  MAPANDAN: 65431,
  NATIVIDAD: 54341,
  POZORRUBIO: 65431,
  ROSALES: 54231,
  "SAN CARLOS CITY": 54321,
  "SAN FABIAN": 54143,
  "SAN JACINTO": 65341,
  "SAN MANUEL": 56421,
  "SAN NICOLAS": 76541,
  "SAN QUINTIN": 87551,
  "SANTA BARBARA": 86541,
  "SANTA MARIA": 97651,
  "SANTO TOMAS": 87551,
  SISON: 87696,
  SUAL: 98676,
  TAYUG: 90899,
  UMINGAN: 76531,
  URBIZTONDO: 65471,
  "URDANETA CITY": 54351,
  VILLASIS: 42341,
  BASCO: 76341,
  ITBAYAT: 56416,
  IVANA: 52351,
  MAHATAO: 65461,
  SABTANG: 54531,
  UYUGAN: 65461,
  ABULUG: 54561,
  ALCALA: 54361,
  ALLACAPAN: 75641,
  AMULUNG: 65461,
  APARRI: 67451,
  BAGGAO: 76461,
  BALLESTEROS: 87651,
  BUGUEY: 65471,
  CALAYAN: 76571,
  CAMALANIUGAN: 64531,
  CLAVERIA: 87691,
  ENRILE: 98791,
  GATTARAN: 87681,
  GONZAGA: 97671,
  IGUIG: 97671,
  "LAL-LO": 87661,
  LASAM: 87681,
  PAMPLONA: 7651,
  PEÑABLANCA: 65436,
  PIAT: 76551,
  RIZAL: 76571,
  "SANCHEZ-MIRA": 56441,
  "SANTA ANA": 86451,
  "SANTA PRAXEDES": 86551,
  "SANTA TERESITA": 76451,
  "SANTO NIÑO (FAIRE)": 7641,
  SOLANA: 65431,
  TUAO: 65451,
  "TUGUEGARAO CITY": 56361,
  ALICIA: 76561,
  ANGADANAN: 65441,
  AURORA: 65441,
  "BENITO SOLIVEN": 71755,
  BURGOS: 76581,
  CABAGAN: 87561,
  CABATUAN: 98761,
  "CAUAYAN CITY": 56761,
  "CITY OF SANTIAGO": 76451,
  CORDON: 76541,
  "DELFIN ALBANO (MAGSAYSAY)": 76561,
  DINAPIGUE: 65331,
  DIVILACAN: 65431,
  ECHAGUE: 65471,
  GAMU: 76451,
  "ILAGAN CITY": 65471,
  JONES: 76551,
  LUNA: 876771,
  MACONACON: 81897,
  MALLIG: 98761,
  NAGUILIAN: 65441,
  PALANAN: 65341,
  QUEZON: 64331,
  QUIRINO: 64321,
  RAMON: 65421,
  "REINA MERCEDES": 54311,
  ROXAS: 65431,
  "SAN AGUSTIN": 54352,
  "SAN GUILLERMO": 76641,
  "SAN ISIDRO": 45651,
  "SAN MANUEL": 76451,
  "SAN MARIANO": 618345,
  "SAN MATEO": 53241,
  "SAN PABLO": 53131,
  "SANTA MARIA": 52434,
  "SANTO TOMAS": 55315,
  TUMAUINI: 53121,
  "ALFONSO CASTANEDA": 65331,
  AMBAGUIO: 65421,
  ARITAO: 87631,
  BAGABAG: 65331,
  BAMBANG: 76521,
  BAYOMBONG: 54331,
  DIADI: 65431,
  "DUPAX DEL NORTE": 763315,
  "DUPAX DEL SUR": 76332,
  KASIBU: 76331,
  KAYAPA: 65421,
  QUEZON: 65465,
  "SANTA FE": 64465,
  SOLANO: 64465,
  VILLAVERDE: 75331,
  AGLIPAY: 64462,
  CABARROGUIS: 64465,
  DIFFUN: 65465,
  MADDELA: 65421,
  NAGTIPUNAN: 65465,
  SAGUDAY: 65431,
  BALER: 56731,
  CASIGURAN: 74531,
  DILASAG: 76521,
  DINALUNGAN: 65465,
  DINGALAN: 75431,
  DIPACULAO: 65421,
  "MARIA AURORA": 65465,
  "SAN LUIS": 54331,
  ABUCAY: 75312,
  BAGAC: 65421,
  "BALANGA CITY": 64521,
  DINALUPIHAN: 75312,
  HERMOSA: 75431,
  LIMAY: 64531,
  MARIVELES: 41542,
  MORONG: 64231,
  ORANI: 42312,
  ORION: 75312,
  PILAR: 75312,
  SAMAL: 76521,
  ANGAT: 75312,
  "BALAGTAS (BIGAA)": 75312,
  BALIUAG: 74521,
  BOCAUE: 64311,
  BULACAN: 54321,
  BUSTOS: 92311,
  CALUMPIT: 92121,
  "DOÑA REMEDIOS TRINIDAD": 83211,
  GUIGUINTO: 82341,
  HAGONOY: 59431,
  "MALOLOS CITY": 93241,
  MARILAO: 65431,
  "MEYCAUAYAN CITY": 65531,
  NORZAGARAY: 75321,
  OBANDO: 73451,
  PANDI: 83211,
  PAOMBONG: 94321,
  PLARIDEL: 82131,
  PULILAN: 82311,
  "SAN ILDEFONSO": 84211,
  "SAN JOSE DEL MONTE CITY": 82311,
  "SAN MIGUEL": 73451,
  "SAN RAFAEL": 72321,
  "SANTA MARIA": 84211,
  ALIAGA: 94121,
  BONGABON: 85211,
  "CABANATUAN CITY": 84211,
  CABIAO: 85321,
  CARRANGLAN: 67121,
  CUYAPO: 85321,
  "GABALDON (BITULOK AND SABANI)": 82321,
  "GAPAN CITY": 455678,
  "GENERAL MAMERTO NATIVIDAD": 73211,
  "GENERAL TINIO (PAPAYA)": 74311,
  GUIMBA: 64212,
  JAEN: 56000,
  LAUR: 82300,
  LICAB: 55000,
  LLANERA: 82300,
  LUPAO: 89020,
  NAMPICUAN: 89003,
  "PALAYAN CITY": 89000,
  PANTABANGAN: 23400,
  PEÑARANDA: 32144,
  QUEZON: 34500,
  RIZAL: 34532,
  "SAN ANTONIO": 33421,
  "SAN ISIDRO": 34388,
  "SAN JOSE CITY": 90594,
  "SAN LEONARDO": 32300,
  "SANTA ROSA": 12230,
  "SANTO DOMINGO": 13470,
  "SCIENCE CITY OF MUÑOZ": 74210,
  TALAVERA: 234490,
  TALUGTUG: 12409,
  ZARAGOZA: 34505,
  "ANGELES CITY": 35000,
  APALIT: 23070,
  ARAYAT: 56000,
  BACOLOR: 14500,
  CANDABA: 89000,
  FLORIDABLANCA: 45070,
  GUAGUA: 54983,
  LUBAO: 90101,
  "MABALACAT CITY": 72131,
  MACABEBE: 82311,
  MAGALANG: 82311,
  MASANTOL: 82311,
  MEXICO: 53111,
  MINALIN: 92311,
  PORAC: 85421,
  "SAN FERNANDO CITY": 72131,
  "SAN LUIS": 74321,
  "SAN SIMON": 65431,
  "SANTA ANA": 87541,
  "SANTA RITA": 82351,
  "SANTO TOMAS": 83211,
  "SASMUAN (SEXMOAN)": 82321,
  ANAO: 85321,
  BAMBAN: 82321,
  CAMILING: 83421,
  CAPAS: 82321,
  "CITY OF TARLAC": 82321,
  CONCEPCION: 82321,
  GERONA: 92311,
  "LA PAZ": 32154,
  MAYANTOC: 43512,
  MONCADA: 53123,
  PANIQUI: 52132,
  PURA: 23540,
  RAMOS: 53002,
  "SAN CLEMENTE": 52000,
  "SAN JOSE": 32100,
  "SAN MANUEL": 42010,
  "SANTA IGNACIA": 23100,
  VICTORIA: 34200,
  BOTOLAN: 62000,
  CABANGAN: 52300,
  CANDELARIA: 52100,
  CASTILLEJOS: 71023,
  IBA: 64000,
  MASINLOC: 89000,
  "OLONGAPO CITY": 23400,
  PALAUIG: 32144,
  "SAN ANTONIO": 34500,
  "SAN FELIPE": 34532,
  "SAN MARCELINO": 33421,
  "SAN NARCISO": 34388,
  "SANTA CRUZ": 90594,
  SUBIC: 32300,
  AGONCILLO: 12230,
  ALITAGTAG: 13470,
  BALAYAN: 74210,
  BALETE: 234490,
  "BATANGAS CITY": 12409,
  BAUAN: 34505,
  CALACA: 35000,
  CALATAGAN: 23070,
  CUENCA: 56000,
  IBAAN: 14500,
  LAUREL: 89000,
  LEMERY: 45070,
  LIAN: 54983,
  "LIPA CITY": 90101,
  LOBO: 92871,
  MABINI: 84211,
  MALVAR: 94351,
  "MATAAS NA KAHOY": 98231,
  NASUGBU: 89451,
  "PADRE GARCIA": 87221,
  ROSARIO: 67831,
  "SAN JOSE": 86721,
  "SAN JUAN": 76421,
  "SAN LUIS": 87231,
  "SAN NICOLAS": 76311,
  "SAN PASCUAL": 94211,
  "SANTA TERESITA": 32100,
  "SANTO TOMAS": 43500,
  TAAL: 61000,
  TALISAY: 54983,
  "TANAUAN CITY": 44000,
  TAYSAN: 34500,
  TINGLOY: 89000,
  TUY: 98711,
  ALFONSO: 89231,
  AMADEO: 98211,
  "BACOOR CITY": 86521,
  CARMONA: 32521,
  "CAVITE CITY": 23176,
  "DASMARIÑAS CITY": 41212,
  "GEN. MARIANO ALVAREZ": 54255,
  "GENERAL EMILIO AGUINALDO": 89000,
  "GENERAL TRIAS CITY": 23400,
  "IMUS CITY": 32144,
  INDANG: 34500,
  KAWIT: 34532,
  MAGALLANES: 33421,
  MARAGONDON: 34388,
  "MENDEZ (MENDEZ-NUÑEZ)": 90594,
  NAIC: 32300,
  NOVELETA: 12230,
  ROSARIO: 13470,
  SILANG: 74210,
  "TAGAYTAY CITY": 234490,
  TANZA: 12409,
  TERNATE: 34505,
  "TRECE MARTIRES CITY": 35000,
  ALAMINOS: 23070,
  BAY: 56000,
  "CABUYAO CITY": 14500,
  "CALAMBA CITY": 89000,
  CALAUAN: 45070,
  CAVINTI: 54983,
  "CITY OF BIÑAN": 90101,
  FAMY: 32100,
  KALAYAAN: 43500,
  LILIW: 61000,
  "LOS BAÑOS": 54983,
  LUISIANA: 44000,
  LUMBAN: 34500,
  MABITAC: 89000,
  MAGDALENA: 34505,
  MAJAYJAY: 32300,
  NAGCARLAN: 23070,
  PAETE: 61000,
  PAGSANJAN: 21000,
  PAKIL: 99093,
  PANGIL: 43500,
  PILA: 44000,
  RIZAL: 34505,
  "SAN PABLO CITY": 45070,
  "SAN PEDRO CITY": 54983,
  "SANTA CRUZ": 14500,
  "SANTA MARIA": 61000,
  "SANTA ROSA CITY": 90101,
  SINILOAN: 52120,
  VICTORIA: 23070,
  AGDANGAN: 61000,
  ALABAT: 21000,
  ATIMONAN: 99093,
  BUENAVISTA: 43500,
  BURDEOS: 44000,
  CALAUAG: 34505,
  CANDELARIA: 45070,
  CATANAUAN: 54983,
  DOLORES: 14500,
  "GENERAL LUNA": 61000,
  "GENERAL NAKAR": 90101,
  GUINAYANGAN: 52120,
  GUMACA: 32100,
  INFANTA: 43500,
  JOMALIG: 61000,
  LOPEZ: 54983,
  LUCBAN: 44000,
  "LUCENA CITY": 34500,
  MACALELON: 89000,
  MAUBAN: 23400,
  MULANAY: 32144,
  "PADRE BURGOS": 34500,
  PAGBILAO: 34532,
  PANUKULAN: 33421,
  PATNANUNGAN: 34388,
  PEREZ: 90594,
  PITOGO: 32300,
  PLARIDEL: 12230,
  POLILLO: 13470,
  QUEZON: 74210,
  REAL: 234490,
  SAMPALOC: 12409,
  "SAN ANDRES": 34505,
  "SAN ANTONIO": 35000,
  "SAN FRANCISCO (AURORA)": 23070,
  "SAN NARCISO": 56000,
  SARIAYA: 14500,
  TAGKAWAYAN: 89000,
  "TAYABAS CITY": 45070,
  TIAONG: 54983,
  UNISAN: 90101,
  ANGONO: 34505,
  BARAS: 32300,
  BINANGONAN: 23070,
  CAINTA: 61000,
  CARDONA: 21000,
  "CITY OF ANTIPOLO": 99093,
  "JALA-JALA": 43500,
  MORONG: 44000,
  PILILLA: 34505,
  "RODRIGUEZ (MONTALBAN)": 45070,
  "SAN MATEO": 54983,
  TANAY: 14500,
  TAYTAY: 61000,
  TERESA: 90101,
  "CITY OF LAS PIÑAS": 52120,
  "CITY OF MAKATI": 34180,
  "CITY OF MUNTINLUPA": 23100,
  "CITY OF PARAÑAQUE": 61000,
  "PASAY CITY": 44000,
  BINONDO: 34500,
  ERMITA: 52120,
  INTRAMUROS: 34180,
  MALATE: 32100,
  PACO: 43500,
  PANDACAN: 61000,
  "PORT AREA": 54983,
  QUIAPO: 44000,
  SAMPALOC: 34500,
  "SAN MIGUEL": 89000,
  "SAN NICOLAS": 45300,
  "SANTA ANA": 45070,
  "SANTA CRUZ": 21000,
  TONDO: 14500,
  "CITY OF MANDALUYONG": 90101,
  "CITY OF MARIKINA": 99093,
  "CITY OF PASIG": 45300,
  "QUEZON CITY": 45000,
  "SAN JUAN CITY": 89000,
  "CALOOCAN CITY": 45300,
  "CITY OF VALENZUELA": 45000,
  "MALABON CITY": 21000,
  "NAVOTAS CITY": 14500,
};

export function getShippingFee(city) {
  return shippingFees[city];
}
